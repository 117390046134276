<template>
  <div>
    <TopMenuSubheadline />
    <div class="app-content-box bg-gray wrapper">
          <ValidationObserver ref="observer" tag="form" >
          <form @submit.prevent="">
            <div class="container">
              <div class="book-form-container">
                <p class="form-error-info text-center" v-if="error">Wypełnij wszystkie obowiązkowe pola formularza</p>
                  <div class="cover-add-container">
                    <input type="file" id="cover-add-file" name="cover-add-file" ref="file" @change="handleFileUpload()">
                    <label for="cover-add-file" class="book-cover-button"></label>
                  </div>
              </div>
            </div>
            <div class="container mt-2">
              <div class="d-table w-100">
                <div class="d-table-cell text-left">
                  <div class="form-radio form-radio-left">
                    <input type="radio" name="radio_b" id="radio_b1" value="KS" v-model="type">
                    <label for="radio_b1">książka</label>
                  </div>
                </div>
                <div class="d-table-cell text-center">
                  <div class="form-radio form-radio-left">
                    <input type="radio" name="radio_b" id="radio_b2" value="AU" v-model="type">
                    <label for="radio_b2">audiobook</label>
                  </div>
                </div>
                <div class="d-table-cell text-right">
                  <div class="form-radio form-radio-left">
                    <input type="radio" name="radio_b" id="radio_b3" value="EB" v-model="type">
                    <label for="radio_b3">ebook</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="container mt-4">
              <ValidationProvider :rules="{required: true}" v-slot="{errors, classes}">
              <div class="input-container">
                <input type="text" class="form-input w-100 control" :class="classes" placeholder="Wpisz tytuł książki *" v-model="title">
              </div>
              <span class="form-error-info text-center">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider :rules="{required: true}" v-slot="{errors, classes}">
              <div class="input-container">
                <input type="text" class="form-input w-100 control" :class="classes" placeholder="Wpisz autora *" v-model="author">
              </div>
              <span class="form-error-info text-center">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider :rules="{required: true}" v-slot="{errors, classes}">
                <div class="input-container">
                  <input type="text" class="form-input w-100 control" :class="classes" placeholder="Wpisz wydawcę *" v-model="publisher">
                </div>
                <span class="form-error-info text-center">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider :rules="{required: true, numeric: true}" v-slot="{errors, classes}">
              <div class="input-container">
                <input type="text" class="form-input w-100 control" :class="classes" placeholder="Wpisz rok wydania *" v-model="published_year">
              </div>
                <span class="form-error-info text-center">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider :rules="{required: true, numeric: true}" v-slot="{errors, classes}">
              <div class="input-container">
                <input type="text" class="form-input w-100 control" :class="classes" placeholder="Wpisz liczbę stron *" v-model="pages">
              </div>
              <span class="form-error-info text-center">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="input-container">
                <router-link to="/wybierz-kategorie">
                  <div class="form-input w-100 control" v-if="addBookSelectedGenres.length > 0"><span v-for="(i, key) in addBookSelectedGenres" :key="key">{{i.label}}<span>, </span> </span></div>
                  <ValidationProvider :rules="{required: true}" v-slot="{errors, classes}" v-if="addBookSelectedGenres.length === 0">
                  <input type="text" class="form-input w-100 control" :class="classes" placeholder="Wybierz kategorie *" v-if="addBookSelectedGenres.length === 0" />
                  <span class="form-error-info text-center">{{ errors[0] }}</span>
                  </ValidationProvider>
                </router-link>
              </div>
              <!-- <div class="input-container">
                <div class="form-select-container control" :class="classes">
                  <select class="form-select" v-model="category">
                    <option value="default" disabled selected>Wybierz kategorię *</option>
                    <option value="category_1">Kategoria 1</option>
                    <option value="category_2">Kategoria 2</option>
                    <option value="category_3">Kategoria 3</option>
                    <option value="category_4">Kategoria 4</option>
                  </select>
                  <span class="select-arrow fas fa-caret-down"></span>
                </div>
              </div> -->
              <div class="input-container">
                <input type="text" class="form-input w-100" placeholder="Wpisz ISBN" v-model="ISBN">
              </div>
              <ValidationProvider :rules="{required: true}" v-slot="{errors, classes}">
              <div class="input-container">
                <textarea type="text" class="form-input w-100 control"
                :class="classes" name="" placeholder="Opis książki *" v-model="description"
                ></textarea>
              </div>
              <span class="form-error-info text-center">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="input-info mt-3">
                * Pole obowiązkowe
              </div>
            </div>

            <!-- <div class="book-form-container">
              <div class="form-group-label no-border-top no-border-bottom">
                <div class="container form-container">
                  <strong>Określ status książki</strong>
                </div>
              </div>
              <div class="form-group form-group-border">
                <div class="container form-container">
                  <div class="form-radio">
                    <input type="radio" name="radio_" id="radio_1" value="want-read" v-model="status">
                    <label for="radio_1">chcę przeczytać</label>
                  </div>
                </div>
              </div>
              <div class="form-group form-group-border">
                <div class="container form-container">
                  <div class="form-radio">
                    <input type="radio" name="radio_" id="radio_2" value="read" v-model="status">
                    <label for="radio_2">przeczytana</label>
                  </div>
                </div>
              </div>
              <div class="form-group form-group-border">
                <div class="container form-container">
                  <div class="form-radio">
                    <input type="radio" name="radio_" id="radio_3" value="reading" v-model="status">
                    <label for="radio_3">aktualnie czytam</label>
                  </div>
                </div>
              </div>
              <div class="form-group form-group-border">
                <div class="container form-container">
                  <div class="form-radio">
                    <input type="radio" name="radio_" id="radio_4" value="later-read" v-model="status">
                    <label for="radio_4">zapisz na później</label>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <div class="book-form-container">
              <div class="form-group-label">
                <div class="container form-container">
                  Wybierz półkę:
                </div>
              </div>
              <div class="form-group form-group-border">
                <div class="container form-container">
                  <div class="form-checkbox-switch">
                    <input type="checkbox" name="checkbox-switch_" id="checkbox-switch_1">
                    <label for="checkbox-switch_1">
                      mój top
                      <span class="switch-pill">
                        <span class="switch-dot"></span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group form-group-border">
                <div class="container form-container">
                  <div class="form-checkbox-switch">
                    <input type="checkbox" name="checkbox-switch_" id="checkbox-switch_2" checked>
                    <label for="checkbox-switch_2">
                      kryminały
                      <span class="switch-pill">
                        <span class="switch-dot"></span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group form-group-border">
                <div class="container form-container">
                  <div class="form-checkbox-switch">
                    <input type="checkbox" name="checkbox-switch_" id="checkbox-switch_3" disabled>
                    <label for="checkbox-switch_3">
                      ulubione J.R.R. Tolkien
                      <span class="switch-pill">
                        <span class="switch-dot"></span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <div class="book-form-container">
              <div class="container form-group-label">
                <div class="form-container">
                  Czy książka jest Twoja?
                </div>
              </div>
              <div class="form-group form-group-border">
                <div class="container form-container">
                  <div class="form-checkbox">
                    <input type="checkbox" name="checkbox_1" id="checkbox_1">
                    <label for="checkbox_1">
                      moja książka
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group form-group-border">
                <div class="container form-container">
                  <div class="form-checkbox">
                    <input type="checkbox" name="checkbox_2" id="checkbox_2" checked>
                    <label for="checkbox_2">
                      pożyczam książkę
                    </label>
                  </div>
                </div>
                <div class="container form-container">
                  <ValidationProvider rules="required" v-slot="{errors, classes}">
                    <div class="input-container">
                      <input type="text" class="form-input w-100 control"
                      :class="classes" placeholder="imię*"
                      v-model="name">
                      <span class="form-error-info text-center">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                  <ValidationProvider rules="required" v-slot="{errors, classes}" >
                    <div class="input-container">
                      <input type="text" class="form-input w-100 control"
                      :class="classes" placeholder="nazwisko*"
                      v-model="surname">
                      <span class="form-error-info text-center">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                  <div class="input-container">
                    <div class="form-calendar">
                      <DatePicker
                      mode="single"
                      :value="selectedDate"
                      color="gray"
                      :input-props='{placeholder: "wybierz termin oddania", class: "form-input form--calendar w-100"}'
                      >
                      </DatePicker>
                    </div>
                  </div>
                  <p class="input-info">*Obowiązkowe</p>
                </div>
              </div>
            </div> -->
            <div class="form-group form-group-footer">
              <div class="container form-container">
                <button type="submit" @click="submit()" class="btn-black-full w-100 mt-3">Zapisz</button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <ModalSlot title="Dodaj książkę" v-if="isPopupShown" @close-modal="closeModal">
        <div class="text-center mb-4 modal-main-title">Książka dodana</div>
          <!-- <div class="mt-4 mb-4 row d-flex justify-content-center">
            <div class="mb-2"><b>{{bookData[2]}}</b></div>
            <br>
            <div>{{bookData[1]}}</div>
          </div> -->
          <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Zamknij</button>
      </ModalSlot>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
// import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, numeric } from 'vee-validate/dist/rules'
import ModalSlot from '../components/ModalSlot'
extend('required', {
  ...required,
  message: 'Pole jest obowiązkowe'
})

extend('numeric', {
  ...numeric,
  message: 'Pole może zawierać jedynie cyfry'
})

export default {
  name: 'AddBook',
  components: {
    // DatePicker,
    ValidationProvider,
    ValidationObserver,
    TopMenuSubheadline,
    ModalSlot
  },
  computed: {
    // addBookSelectedGenres () { return this.$store.state.addBookSelectedGenres },

    title: {
      get () {
        return this.$store.state.addBook.title
      },
      set (value) {
        this.$store.commit('updateAddBookTitle', value)
      }
    },
    author: {
      get () {
        return this.$store.state.addBook.author
      },
      set (value) {
        this.$store.commit('updateAddBookAuthor', value)
      }
    },
    publisher: {
      get () {
        return this.$store.state.addBook.publisher
      },
      set (value) {
        this.$store.commit('updateAddBookPublisher', value)
      }
    },
    published_year: {
      get () {
        return this.$store.state.addBook.published_year
      },
      set (value) {
        this.$store.commit('updateAddBookPublishedYear', value)
      }
    },
    pages: {
      get () {
        return this.$store.state.addBook.pages
      },
      set (value) {
        this.$store.commit('updateAddBookPages', value)
      }
    },
    ISBN: {
      get () {
        return this.$store.state.addBook.isbn
      },
      set (value) {
        this.$store.commit('updateAddBookISBN', value)
      }
    },
    description: {
      get () {
        return this.$store.state.addBook.description
      },
      set (value) {
        this.$store.commit('updateAddBookDescription', value)
      }
    },
    addBookSelectedGenres: {
      get () {
        return this.$store.state.addBookSelectedGenres
      },
      set (value) {
        this.$store.commit('updateAddBookSelectedGenres', value)
      }
    }
  },
  data () {
    return {
      isPopupShown: false,
      error: false,
      file: '',
      type: 'KS',
      selected: this.$store.state.addBookSelectedGenres,
      // title: '',
      // author: '',
      // publisher: '',
      // published_year: '',
      // pages: '',
      category: '',
      // ISBN: '',
      // description: '',
      status: 'want-read',
      selectedDate: '',
      name: '',
      surname: ''
    }
  },
  methods: {
    closeModal (value) {
      this.isPopupShown = value
      this.title = ''
      this.author = ''
      this.publisher = ''
      this.published_year = ''
      this.pages = ''
      this.ISBN = ''
      this.description = ''
      this.addBookSelectedGenres = ''
      this.$refs.observer.reset()
    },
    handleFileUpload () {
      this.file = this.$refs.file.files[0]
      const formData = new FormData()
      formData.append('file', this.file)
    },
    async submit () {
      const valid = await this.$refs.observer.validate()
      if (valid === true) {
        this.addBookToDatabase()
      }
    },
    addBookToDatabase () {
      const genresId = this.addBookSelectedGenres.map(e => e.id).join(';')
      const formData = new FormData()
      formData.append('file', this.file)
      const getData = `genres=${genresId}&type=${this.type}&author=${this.author}&title=${this.title}
      &pages=${this.pages}&isbn=${this.ISBN}&description=${this.description}
      &publisher=${this.publisher}&published_year=${this.published_year}`
      this.$https('/book/add', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
          // 'Content-Type': 'multipart/form-data'
        },
        data: getData
      }).then(response => {
        this.isPopupShown = true
      })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .form-control {
    background-color: transparent;
  }

  .control {
    background-color: transparent;
        &.invalid {
      border-color: #ff0000 !important;
    }

    &.valid {
      border-color: #000 !important;
    }
  }

 .form-group.form-group-footer {
   border-top: none;
 }
  .wrapper {
    padding-bottom: 50px;
  }

  .form-error-info {
    font-size: 12px;
    margin: 5px 0 10px 0;
  }
</style>
